<template>
    <div class="sidebar"
         :class="{'sidebar_closed': isMobile && !isMenuOpened}"
         :style="cssVars">
        <div class="sidebar__wrapper">
            <v-layout v-if="isMobile"
                      justify-end
                      class="mt-3 mr-3">
                <v-icon @click="closeMenu">{{ ICONS.CLOSE }}</v-icon>
            </v-layout>
            <ul class="sidebar__list">
                <MenuItem v-for="(parent, index) in menu"
                          :key="index"
                          :parent="parent"
                          :index="index"
                          :mini-variant="miniVariant"
                          :navbar-width="navbarWidth"></MenuItem>
                <li v-if="isMobile" class="sidebar__mobile-item">
                    <Logout :icon-size="18" :bold-text="false"></Logout>
                </li>
            </ul>
            <p class="collapse"
               v-if="!isMobile"
               @click="toggleMenu">
                <v-icon>{{ collapseIcon }}</v-icon>
                <span v-if="!miniVariant" class="ml-4">{{ $t('buttons.collapse_sidebar') }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { fullNavbarWidth, miniNavbarWidth, navbarHeight } from '@/constants/navbar';
import MenuItem from '@/components/menu/MenuItem';
import Logout from '@/components/auth/Logout';
import { ICONS } from '@/constants/icons';
import ability from '@/mixins/ability';
import { mapState } from 'vuex';
import { hasAccessToRoute, Routes } from '@/helpers/auth-verify';

export default {
    name: 'Navigation',
    mixins: [ability],
    components: { MenuItem, Logout },
    data: () => ({
        ICONS,
        sound: new Audio(require('@/assets/alert.mp3'))
    }),
    computed: {
        ...mapFields('navigation', [
            'isMenuOpened'
        ]),
        ...mapState('incidents', [
            'newIncidentsNotification'
        ]),
        menu () {
            return [
                // {
                //     title: this.$t('navigation.home'),
                //     icon: ICONS.HOME,
                //     route: 'home',
                //     visibility: true,
                //     dataTest: 'home'
                // },
                {
                    title: this.$t('navigation.reports'),
                    icon: ICONS.REPORTS,
                    visibility: hasAccessToRoute(this.$ability, Routes.REPORTS),
                    children: [
                        {
                            title: this.$t('navigation.planning_reports'),
                            route: Routes.REPORTS_PLANNING,
                            visibility: hasAccessToRoute(this.$ability, Routes.REPORTS_PLANNING)
                        },
                        {
                            title: this.$t('navigation.inspections_infringement_report'),
                            route: Routes.REPORTS_INSPECTIONS_INFRINGEMENT,
                            visibility: hasAccessToRoute(this.$ability, Routes.REPORTS_INSPECTIONS_INFRINGEMENT)
                        },
                        {
                            title: this.$t('navigation.global_violations_report'),
                            route: Routes.REPORTS_GLOBAL_VIOLATIONS,
                            visibility: hasAccessToRoute(this.$ability, Routes.REPORTS_GLOBAL_VIOLATIONS),
                            divider: true
                        },
                        {
                            title: this.$t('navigation.incidents_types_reports'),
                            route: Routes.REPORTS_INCIDENTS_TYPES,
                            visibility: hasAccessToRoute(this.$ability, Routes.REPORTS_INCIDENTS_TYPES)
                        },
                        {
                            title: this.$t('navigation.incidents_quantity'),
                            route: Routes.REPORTS_INCIDENTS_QUANTITY,
                            visibility: hasAccessToRoute(this.$ability, Routes.REPORTS_INCIDENTS_QUANTITY),
                            divider: true
                        },
                        {
                            title: this.$t('navigation.custom_reports'),
                            route: Routes.REPORTS_CUSTOM,
                            visibility: false,
                            divider: true
                        },
                        {
                            title: this.$t('navigation.time_reports'),
                            route: Routes.REPORTS_EZT,
                            visibility: true,
                            divider: true
                        },
                        {
                            title: this.$t('navigation.scoring'),
                            route: Routes.REPORTS_SEST_LUV,
                            visibility: true
                        }
                        // Hidden this report type due the error on backend side. As far as we know it's not used.
                        // {
                        //     title: this.$t('navigation.scoring_weeks'),
                        //     route: 'sestLuvWeeksReports',
                        //     visibility: false,
                        //     dataTest: 'customReports',
                        //     divider: true
                        // },
                        // {
                        //     title: this.$t('navigation.nfc_reports'),
                        //     route: 'nfcReports',
                        //     visibility: false,
                        //     dataTest: 'nfcReports'
                        // }
                    ]
                },
                {
                    title: this.$t('navigation.inspections'),
                    icon: ICONS.INSPECTIONS,
                    route: Routes.INSPECTIONS,
                    visibility: hasAccessToRoute(this.$ability, Routes.INSPECTIONS)
                },
                {
                    title: this.$t('navigation.incidents'),
                    icon: ICONS.INCIDENTS,
                    route: Routes.INCIDENTS,
                    visibility: hasAccessToRoute(this.$ability, Routes.INCIDENTS),
                    hasNew: this.newIncidentsNotification
                },
                {
                    title: this.$t('navigation.tracks'),
                    icon: ICONS.TRACKS,
                    route: Routes.TRACKS,
                    visibility: hasAccessToRoute(this.$ability, Routes.TRACKS)
                },
                {
                    title: this.$t('navigation.customers_and_sites'),
                    icon: ICONS.ACCOUNT_GROUP,
                    visibility: hasAccessToRoute(this.$ability, Routes.CUSTOMERS_AND_SITES),
                    children: [
                        {
                            title: this.$t('navigation.customers'),
                            route: Routes.CUSTOMERS,
                            visibility: hasAccessToRoute(this.$ability, Routes.CUSTOMERS)
                        },
                        {
                            title: this.$t('navigation.sites'),
                            route: Routes.SITES,
                            visibility: hasAccessToRoute(this.$ability, Routes.SITES)
                        },
                        {
                            title: this.$t('management.customers_and_sites.locations_table.title'),
                            route: Routes.LOCATIONS,
                            visibility: hasAccessToRoute(this.$ability, Routes.LOCATIONS)
                        }]
                },
                {
                    title: this.$t('navigation.persons'),
                    icon: ICONS.ACCOUNT,
                    route: Routes.EMPLOYEES,
                    visibility: hasAccessToRoute(this.$ability, Routes.EMPLOYEES)
                },
                {
                    title: this.$t('navigation.subcontractors'),
                    icon: ICONS.ACCOUNT_SWITCH,
                    route: Routes.SUBCONTRACTORS,
                    visibility: hasAccessToRoute(this.$ability, Routes.SUBCONTRACTORS)
                },
                {
                    title: this.$t('navigation.elements'),
                    icon: ICONS.ELEMENT,
                    route: Routes.ELEMENTS,
                    visibility: hasAccessToRoute(this.$ability, Routes.ELEMENTS)
                },
                {
                    title: this.$t('navigation.schedule'),
                    icon: ICONS.CALENDAR_CHECK,
                    route: Routes.SCHEDULING,
                    visibility: hasAccessToRoute(this.$ability, Routes.SCHEDULING)
                },
                {
                    title: this.$t('navigation.configuration'),
                    icon: ICONS.CONFIGURATION,
                    visibility: hasAccessToRoute(this.$ability, Routes.CONFIGURATION),
                    children: [
                        {
                            title: this.$t('navigation.cities'),
                            route: Routes.CONFIGURATION_AREA,
                            visibility: hasAccessToRoute(this.$ability, Routes.CONFIGURATION_AREA)
                        },
                        {
                            title: this.$t('navigation.templates'),
                            route: Routes.CONFIGURATION_TEMPLATES,
                            visibility: hasAccessToRoute(this.$ability, Routes.CONFIGURATION_TEMPLATES)
                        },
                        {
                            title: this.$t('navigation.positions'),
                            route: Routes.CONFIGURATION_POSITIONS,
                            visibility: hasAccessToRoute(this.$ability, Routes.CONFIGURATION_POSITIONS)
                        },
                        {
                            title: this.$t('navigation.incidents_types_new'),
                            route: Routes.CONFIGURATION_INCIDENTS_TYPES,
                            visibility: hasAccessToRoute(this.$ability, Routes.CONFIGURATION_INCIDENTS_TYPES)
                        },
                        {
                            title: this.$t('navigation.statuses'),
                            route: Routes.CONFIGURATION_INCIDENTS_STATUSES,
                            visibility: hasAccessToRoute(this.$ability, Routes.CONFIGURATION_INCIDENTS_STATUSES)
                        },
                        {
                            title: this.$t('navigation.subcontractors_types'),
                            route: Routes.CONFIGURATION_SUBCONTRACTORS_TYPES,
                            visibility: hasAccessToRoute(this.$ability, Routes.CONFIGURATION_SUBCONTRACTORS_TYPES)
                        },
                        {
                            title: this.$t('navigation.object_groups'),
                            route: Routes.CONFIGURATION_SITES_GROUPS,
                            visibility: hasAccessToRoute(this.$ability, Routes.CONFIGURATION_SITES_GROUPS)
                        },
                        {
                            title: this.$t('navigation.persons_groups'),
                            route: Routes.CONFIGURATION_EMPLOYEES_GROUPS,
                            visibility: hasAccessToRoute(this.$ability, Routes.CONFIGURATION_EMPLOYEES_GROUPS)
                        },
                        {
                            title: this.$t('navigation.data_imports'),
                            route: Routes.CONFIGURATION_IMPORTS,
                            visibility: hasAccessToRoute(this.$ability, Routes.CONFIGURATION_IMPORTS)
                        }
                    ]
                },
                {
                    title: this.$t('navigation.settings'),
                    icon: ICONS.SETTINGS,
                    visibility: hasAccessToRoute(this.$ability, Routes.SETTINGS),
                    children: [
                        {
                            title: this.$t('navigation.general'),
                            route: Routes.CONFIG_GLOBAL,
                            visibility: hasAccessToRoute(this.$ability, Routes.CONFIG_GLOBAL)
                        },
                        // {
                        //     title: this.$t('navigation.inspections'),
                        //     route: 'settingsInspections',
                        //     visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.SYSTEM_SETTINGS)
                        // },
                        {
                            title: this.$t('navigation.system_logs'),
                            route: Routes.SYSTEM_LOG,
                            visibility: hasAccessToRoute(this.$ability, Routes.SYSTEM_LOG)
                        },
                        {
                            title: this.$t('labels.translations'),
                            route: Routes.LOCALES,
                            visibility: hasAccessToRoute(this.$ability, Routes.LOCALES)
                        },
                        // {
                        //     title: this.$t('navigation.sms_gate'),
                        //     route: 'smsGate',
                        //     visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.SYSTEM_SETTINGS)
                        // },
                        {
                            title: this.$t('navigation.holiday_calendar'),
                            route: Routes.HOLIDAYS,
                            visibility: hasAccessToRoute(this.$ability, Routes.HOLIDAYS)
                        }
                    ]
                }
            ];
        },
        collapseIcon () {
            return this.isMenuOpened ? ICONS.DOUBLE_LEFT : ICONS.DOUBLE_RIGHT;
        },
        miniVariant () {
            return !this.isMenuOpened && !this.isMobile;
        },
        navbarWidth () {
            return this.isMenuOpened ? fullNavbarWidth : miniNavbarWidth;
        },
        cssVars () {
            return {
                '--nav_width': this.navbarWidth,
                '--nav_height': navbarHeight
            };
        },
        isMobile () {
            return this.$vuetify.breakpoint.smAndDown;
        }
    },
    watch: {
        miniVariant: {
            handler (val) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        window.sessionStorage.setItem('miniVariant', val);
                    }, 70);
                });
            },
            immediate: true
        },
        newIncidentsNotification: {
            handler (val) {
                if (val) {
                    this.playSound();
                } else {
                    this.stopSound();
                }
            },
            immediate: true
        }
    },
    methods: {
        toggleMenu () {
            this.isMenuOpened = !this.isMenuOpened;
        },
        closeMenu () {
            this.isMenuOpened = false;
        },
        playSound () {
            this.sound.loop = true;
            this.sound.play();
        },
        stopSound () {
            this.sound.pause();
            this.sound.currentTime = 0;
        }
    },
    created () {
        if (!this.isMobile) {
            const _val = window.sessionStorage.getItem('miniVariant') === 'true';
            this.isMenuOpened = !_val;
        } else {
            this.isMenuOpened = false;
        }
    },
    beforeDestroy () {
        this.$store.commit('incidents/SET_NEW_INCIDENTS_NOTIFICATION', false);
        this.stopSound();
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.full-height {
    min-height: 100%;
}

.sidebar {
    transition: width 0.3s, left 0.3s;
    position: fixed;
    width: var(--nav_width);
    top: calc(1px + #{var(--nav_height)});
    height: calc(100vh - #{var(--nav_height)});
    bottom: 0;
    left: 0;
    background-color: $light_grey;
    box-shadow: inset -1px 0 0 #dbdbdb;
    transform: translate3d(0, 0, 0);

    &__wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }

    &__list {
        padding-left: 0;
        list-style: none;
        margin-bottom: 60px;
    }

    &__mobile-item {
        @include navbar_item_title;
    }
}

.collapse {
    width: var(--nav_width);
    overflow: hidden;
    white-space: nowrap;
    border-top: 1px solid $grey_lighten_2;
    transition: width 0.3s;
    margin-bottom: 0;
    padding: 10px 16px;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    background: $tertiary;
    display: flex;
    align-items: center;
    justify-content: left;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .sidebar {
        width: 100%;
        top: 0;
        height: 100vh;
        overflow-y: auto;
        display: block;
        transition: display 0.3s;

        &_closed {
            display: none;
        }

        &__list {
            margin-bottom: 5px;
        }
    }
}
</style>
